import { Stack, Typography } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/_main/data-validation/patient/_search/')({
  component: SearchIndex,
});

function SearchIndex() {
  const { t } = useTranslation();
  return (
    <Stack textAlign="center" gap={2}>
      <Typography level="h3">{t('data-validation.search.title')}</Typography>
      <Stack gap={1}>
        <Typography>{t('data-validation.search.description')}</Typography>
        <Typography>{t('data-validation.search.tip')}</Typography>
      </Stack>
    </Stack>
  );
}
