import { PatientDto } from '../../api/generated.ts';

export function getPid(patient: PatientDto) {
  return patient.identifier.find((i) => i.system === 'https://heureka.health/pid')?.value;
}

function getSocialSecurityNumber(patient: PatientDto) {
  return patient.identifier.find((i) => i.system === 'urn:oid:2.16.756.5.32')?.value;
}

export function getFormattedSocialSecurityNumber(patient: PatientDto) {
  const value = getSocialSecurityNumber(patient);
  if (!value) return undefined;

  return `${value.slice(0, 3)}.${value.slice(3, 7)}.${value.slice(7, 11)}.${value.slice(11)}`;
}
