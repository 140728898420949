import { captureException } from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { AxiosError, HttpStatusCode, isAxiosError } from 'axios';
import { ErrorWithInterpretation } from '../errors/ErrorWithInterpretation.ts';
import { interpretError } from '../errors/interpretError.tsx';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true, // query errors are generally best handled by an ErrorBoundary
      retry: (failureCount, error: unknown) => {
        if (error instanceof ErrorWithInterpretation) {
          error = error.originalError;
        }
        if (isAxiosError(error)) {
          const statusCode = error.response?.status;
          if (
            error.code === AxiosError.ERR_NETWORK ||
            statusCode === HttpStatusCode.GatewayTimeout ||
            statusCode === HttpStatusCode.ServiceUnavailable ||
            statusCode === HttpStatusCode.BadGateway
          ) {
            return failureCount < 3;
          }
        }
        return false;
      },
    },
    mutations: {
      throwOnError: (error) => {
        const { shouldBeHandledAtRoot } = interpretError(error);
        return !!shouldBeHandledAtRoot;
      },
    },
  },
  mutationCache: new MutationCache({
    onError: (error) => captureException(error),
  }),
  queryCache: new QueryCache({
    onError: (_error, query) => {
      // Workaround for this: https://github.com/TanStack/query/issues/2712
      //
      // What is the issue?
      // 1. When an error in a suspense query occurs, an error is thrown and caught by an error boundary.
      // 2. The error is stored in the query cache (this is by design).
      // 3. The next time the same query is requested by a component, it doesn't retry the request and returns the error
      //    from cache instead.
      //
      // The culprit is this piece of code, disabling `retryOnMount`:
      // https://github.com/TanStack/query/blob/564e549687520866c0fde69b04104762f30a8227/packages/react-query/src/errorBoundaryUtils.ts#L36
      //
      // The QueryErrorResetBoundary from react-query is supposed to solve this, but it doesn't really work.
      // As far as I can tell, it relies on the errored query to be the first query that is rendered after a reset.
      // But that is not always the case (e.g. when navigating away from the error and then coming back to it).
      //
      // How does this workaround work?
      // If a query results in an error and no observers are active, it is removed from the cache. This forces a retry
      // the next time the query is used. The timeout is necessary to avoid an infinite loop of retries.
      setTimeout(() => {
        if (query.getObserversCount() === 0) {
          // Only remove the query from cache if there are no active observers (non-throwing query hooks).
          void queryClient.removeQueries({
            queryKey: query.queryKey,
          });
        }
      }, 100);
    },
  }),
});
