import { CssBaseline, CssVarsProvider } from '@healthinal/ui';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { queryClient } from './api/queryClient.ts';
import { SupportFormProvider } from './common/components/SupportForm.tsx';
import { initSentry } from './errors/sentry.ts';
import { initI18next } from './i18n/i18next.ts';
import { router } from './router.ts';
import { theme } from './theme.ts';

initI18next();
initSentry();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <SupportFormProvider>
          <RouterProvider router={router} />
        </SupportFormProvider>
      </CssVarsProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
