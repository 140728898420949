import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { useLocation } from '@tanstack/react-router';
import { HttpStatusCode, isAxiosError } from 'axios';
import { ReactNode, useEffect, useRef } from 'react';
import { LoginScreen } from '../auth/LoginScreen.tsx';
import { ErrorView } from './ErrorView.tsx';
import { interpretError } from './interpretError.tsx';

interface ErrorBoundaryProps {
  children: ReactNode;
  isRoot?: boolean;
}

export function ErrorBoundary({ children, isRoot }: ErrorBoundaryProps) {
  const ref = useRef<SentryErrorBoundary>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (ref.current?.state.error) {
      // reset errors when user navigates (otherwise users wouldn't be able to navigate away from an error)
      ref.current?.resetErrorBoundary();
    }
  }, [pathname]);

  return (
    <SentryErrorBoundary
      ref={ref}
      onError={(error) => {
        const { shouldBeHandledAtRoot } = interpretError(error);
        if (shouldBeHandledAtRoot && !isRoot) {
          throw error;
        }
      }}
      fallback={({ error, eventId }) => {
        if (isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized) {
          return <LoginScreen />;
        }
        return <ErrorView {...interpretError(error)} sentryId={eventId} />;
      }}>
      {children}
    </SentryErrorBoundary>
  );
}
