import { Card, Chip, Icons, Stack, Tooltip, Typography } from '@healthinal/ui';
import { createColumnHelper, IdentifiedColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MedicationStatementDto } from '../../api/generated.ts';
import { DataTable } from '../../common/components/DataTable.tsx';
import { TabularNums } from '../../common/components/TabularNums.tsx';
import { formatDate } from '../../i18n/date.ts';
import { usePatientData } from '../usePatientData.tsx';
import { MedicationDosage } from './MedicationDosage.tsx';
import { isTabularDosage, transformDosage } from './dosage.ts';

interface MedicationViewProps {
  pid: string;
}

const columnHelper = createColumnHelper<MedicationStatementDto>();

export function MedicationView({ pid }: MedicationViewProps) {
  const { t } = useTranslation();
  const { data } = usePatientData(pid);

  const hasOnlyTabularDosages = useMemo(
    () => data.medicationStatements.every((statement) => isTabularDosage(transformDosage(statement.dosage))),
    [data],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((statement) => statement.medicationCodeableConcept?.text ?? undefined, {
        id: 'medication',
        header: t('data-validation.medication.medication'),
        cell: ({ getValue, row }) => (
          <>
            {getValue()}
            {row.original.dosage.some((dosage) => dosage.asNeededBoolean) && (
              <Chip size="sm" color="primary" sx={{ marginLeft: 1 }}>
                {t('data-validation.medication.as-needed')}
              </Chip>
            )}
          </>
        ),
      }),
      columnHelper.display({
        id: 'dosage',
        header: t('data-validation.medication.dosage'),
        cell: ({ row }) => <MedicationDosage dosages={row.original.dosage} />,
        meta: {
          width: hasOnlyTabularDosages ? 160 : undefined,
        },
      }),
      columnHelper.accessor(
        (statement) =>
          statement.dosage
            .flatMap((dosage) => [
              dosage.patientInstruction,
              ...dosage.additionalInstruction.map((instruction) => instruction.text),
            ])
            .filter((instruction) => !!instruction)
            .join(', ') || undefined,
        {
          id: 'instruction',
          header: t('data-validation.medication.instruction'),
          sortUndefined: 'last',
          cell: ({ getValue, row }) => (
            <Stack direction="row" alignItems="center" gap={2}>
              {getValue()}
              {row.original.note.length > 0 && (
                <Tooltip
                  sx={{ maxWidth: 400 }}
                  title={
                    <Stack>
                      <Typography level="title-sm" textColor="inherit">
                        {t('data-validation.medication.note')}
                      </Typography>
                      {row.original.note.map((annotation, index) => (
                        <Typography key={index}>{annotation.text}</Typography>
                      ))}
                    </Stack>
                  }>
                  <Icons.StickyNote2Outlined fontSize="md" />
                </Tooltip>
              )}
            </Stack>
          ),
        },
      ),
      columnHelper.accessor((statement) => statement.effectivePeriod?.start ?? undefined, {
        id: 'start',
        header: t('from'),
        ...dateColumn,
      }),
      columnHelper.accessor((statement) => statement.effectivePeriod?.end ?? undefined, {
        id: 'end',
        header: t('until'),
        ...dateColumn,
      }),
    ],
    [t, hasOnlyTabularDosages],
  );

  return (
    <Card sx={{ padding: 0 }}>
      <DataTable
        columns={columns}
        initialState={{ sorting: [{ id: 'end', desc: true }] }}
        enableSortingRemoval={false}
        data={data.medicationStatements}
      />
    </Card>
  );
}

const dateColumn: IdentifiedColumnDef<MedicationStatementDto, string | undefined> = {
  cell: ({ getValue }) => <TabularNums>{formatDate(getValue())}</TabularNums>,
  meta: {
    width: 104,
  },
};
