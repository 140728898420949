import { ErrorInterpretation } from './interpretError.tsx';

export class ErrorWithInterpretation extends Error {
  constructor(
    public interpretation: ErrorInterpretation,
    public originalError?: unknown,
  ) {
    super(interpretation.title);
  }
}
