import { AnimationItem } from 'lottie-web';
import { useRef } from 'react';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import { useInterval } from 'usehooks-ts';
import animationData from './HeurekaSpinner.json';

const sizes = {
  sm: 64,
  md: 96,
  lg: 160,
};

interface HeurekaSpinnerProps {
  size?: keyof typeof sizes;
  loopInterval?: number;
}

export function HeurekaSpinner({ size = 'sm', loopInterval }: HeurekaSpinnerProps) {
  const playerRef = useRef<AnimationItem>(null);

  useInterval(() => {
    playerRef.current?.stop();
    playerRef.current?.play();
  }, loopInterval ?? null);

  return (
    <Lottie
      ref={playerRef}
      loop={loopInterval === undefined}
      play
      animationData={animationData}
      style={{ width: sizes[size] }}
    />
  );
}
