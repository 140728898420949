import { Box, Button, Card, Stack, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { PortalLoginOption, useLoginOptionsSuspense } from '../api/generated.ts';
import swissMadeSoftware from '../assets/swissmade-software-logo.svg';
import { AuthHeader } from '../common/components/AuthHeader.tsx';
import { WhatIsHeureka } from '../common/components/WhatIsHeureka.tsx';
import { hardNavigate } from '../common/utils/hardNavigate.ts';
import { contentWidth } from '../theme.ts';

export function LoginScreen() {
  const { t } = useTranslation();
  const { data: loginOptions } = useLoginOptionsSuspense();
  return (
    <>
      <AuthHeader />
      <Stack gap={8} direction="row" alignSelf="center" alignItems="center" width={contentWidth} marginBottom={5}>
        <Card sx={{ width: 400, flexShrink: 0, padding: 4, gap: 4 }}>
          <Typography level="h3" textAlign="center" padding={0.5}>
            {t('welcome.title')}
          </Typography>
          <Typography level="body-md">{t('error.unauthenticated.description')}</Typography>
          <Stack gap={4}>
            <Stack gap={2}>
              {loginOptions.map((option) => (
                <LoginButton key={option} option={option} />
              ))}
            </Stack>
            <Stack gap={2}>
              <Typography level="body-sm" textAlign="center">
                {t('not-yet-installed')}
              </Typography>
              <Button variant="soft" component="a" href="https://heureka.health/sign-up" target="_blank">
                {t('request-installation')}
              </Button>
            </Stack>
          </Stack>
        </Card>
        <WhatIsHeureka />
      </Stack>

      <Box marginTop="auto" marginBottom={5} alignSelf="center">
        <a href="https://www.swissmadesoftware.org" target="_blank" rel="noreferrer">
          <img src={swissMadeSoftware} alt={t('swissmade-software-alternative-text')} />
        </a>
      </Box>
    </>
  );
}

interface LoginButtonProps {
  option: PortalLoginOption;
}

function LoginButton({ option }: LoginButtonProps) {
  const { t } = useTranslation();

  const sx =
    option === PortalLoginOption.HIN
      ? {
          background: '#f29800',
          '&:hover': {
            background: '#e08e00',
          },
        }
      : {};

  const loginUrl = new URL('/portal-api/login', window.location.href);
  loginUrl.searchParams.append('login-option', option);

  return (
    <Button
      onClick={() => {
        hardNavigate(loginUrl, { saveContinueHref: true });
      }}
      sx={sx}>
      {t(`login-with.${option.toLowerCase()}`)}
    </Button>
  );
}
