import { Box, Button, Icons, Stack } from '@healthinal/ui';
import { useRouter } from '@tanstack/react-router';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface SubPageHeaderProps {
  children?: ReactNode;
}

export function SubPageHeader({ children }: SubPageHeaderProps) {
  const { t } = useTranslation();
  const { history } = useRouter();
  return (
    <Stack direction="row" alignItems="center" minHeight={48} marginBottom={5}>
      <Box flex={1}>
        <Button variant="plain" color="neutral" startDecorator={<Icons.ChevronLeft />} onClick={() => history.back()}>
          {t('back')}
        </Button>
      </Box>
      {children}
      <Box flex={1} /* spacer so the middle segment is centered */ />
    </Stack>
  );
}
