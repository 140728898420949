import { styled, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { PisType } from '../api/generated.ts';
import { theme } from '../theme.ts';
import aeskulapIcon from './icons/aeskulap.svg';
import axenitaIcon from './icons/axenita.svg';
import curamedIcon from './icons/curamed.svg';
import elexisIcon from './icons/elexis.svg';
import pexiiIcon from './icons/pexii.svg';
import simedIcon from './icons/simed.svg';
import tomedoIcon from './icons/tomedo.svg';
import vitomedIcon from './icons/vitomed.svg';
import winmedIcon from './icons/winmed.svg';

interface PisBadgeProps {
  pis: PisType;
}

const icons: Record<PisType, string | undefined> = {
  AESKULAP: aeskulapIcon,
  AXENITA: axenitaIcon,
  CURAMED: curamedIcon,
  ELEXIS: elexisIcon,
  ELEXIS_DATABASE: elexisIcon,
  MOCK: undefined,
  PEX_II: pexiiIcon,
  SIMED: simedIcon,
  TOMEDO: tomedoIcon,
  VITOMEDV2: vitomedIcon,
  WINMED: winmedIcon,
};

export function PisBadge({ pis }: PisBadgeProps) {
  const { t } = useTranslation();
  const icon = icons[pis];
  return (
    <Badge>
      {icon && <img src={icon} alt="" />}
      <Typography level="body-xs">{t(`pis.${pis}`)}</Typography>
    </Badge>
  );
}

const Badge = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  height: 24,
  padding: '0 6px',
  gap: 4,
  border: `solid 1px ${theme.vars.palette.neutral.outlinedBorder}`,
  borderRadius: theme.radius.sm,
});
