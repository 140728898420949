import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import {
  DataProjectGrantRevocationReason,
  getGetGrantsOfDataProjectQueryKey,
  getReadDataProjectsQueryKey,
  useRevokeGrantsOfDataProject,
} from '../api/generated.ts';
import { useHealthcareProviderId } from '../user/useHealthcareProviderId.ts';

export function useRevokeGrants(dataProjectId: string, options?: Parameters<typeof useRevokeGrantsOfDataProject>[0]) {
  const healthcareProviderId = useHealthcareProviderId();
  const queryClient = useQueryClient();

  const [reasons, setReasons] = useState(() => new Set<DataProjectGrantRevocationReason>());
  const [reasonComment, setReasonComment] = useState('');

  const mutation = useRevokeGrantsOfDataProject({
    ...options,
    mutation: {
      ...options?.mutation,
      onSuccess: (...args) => {
        void queryClient.invalidateQueries({
          queryKey: getReadDataProjectsQueryKey({ grantedForHealthcareProviderId: healthcareProviderId }),
        });
        void queryClient.invalidateQueries({
          queryKey: getGetGrantsOfDataProjectQueryKey(dataProjectId, { healthcareProviderId }),
        });
        options?.mutation?.onSuccess?.(...args);
      },
    },
  });

  const variables = {
    id: dataProjectId,
    params: {
      healthcareProviderId,
      reasons: Array.from(reasons),
      reasonComment: reasonComment || undefined,
    },
  };

  return {
    ...mutation,
    mutateAsync: () => mutation.mutateAsync(variables),
    mutate: () => mutation.mutate(variables),
    reasons,
    setReasons,
    reasonComment,
    setReasonComment,
  };
}
