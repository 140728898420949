import { Card, Icons, Stack, Typography } from '@healthinal/ui';
import { createColumnHelper, IdentifiedColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionDto } from '../../api/generated.ts';
import { DataTable } from '../../common/components/DataTable.tsx';
import { TabularNums } from '../../common/components/TabularNums.tsx';
import { formatDate } from '../../i18n/date.ts';
import { usePatientData } from '../usePatientData.tsx';

interface ConditionsViewProps {
  pid: string;
}

const columnHelper = createColumnHelper<ConditionDto>();

export function ConditionsView({ pid }: ConditionsViewProps) {
  const { t } = useTranslation();
  const { data } = usePatientData(pid);

  const columns = useMemo(
    () => [
      columnHelper.accessor((condition) => condition.code?.coding.map((coding) => coding.code).join(', '), {
        id: 'code',
        header: t('data-validation.conditions.code'),
        meta: {
          width: 88,
        },
      }),
      columnHelper.accessor(
        (condition) => [
          condition.code?.text ?? condition.code?.coding.map((coding) => coding.display).join(', '),
          condition.note.map((note) => note.text).join('; '),
        ],
        {
          id: 'condition',
          header: t('data-validation.conditions.condition'),
          cell: ({ getValue }) => {
            const [codeText, note] = getValue();
            return (
              <Stack direction="row" columnGap={2} flexWrap="wrap">
                {codeText && <Typography>{codeText}</Typography>}
                {note && codeText !== note && (
                  <Typography textColor="text.tertiary" startDecorator={<Icons.StickyNote2Outlined />}>
                    {note}
                  </Typography>
                )}
              </Stack>
            );
          },
        },
      ),
      columnHelper.accessor((condition) => condition.onsetDateTime ?? undefined, {
        id: 'onset-date',
        header: t('from'),
        ...dateColumn,
      }),
      columnHelper.accessor((condition) => condition.abatementDateTime ?? undefined, {
        id: 'abatement-date',
        header: t('until'),
        ...dateColumn,
      }),
      columnHelper.accessor((condition) => condition.recordedDate ?? undefined, {
        id: 'recorded-date',
        header: t('data-validation.conditions.recorded-date'),
        ...dateColumn,
      }),
    ],
    [t],
  );

  return (
    <Card sx={{ padding: 0 }}>
      <DataTable
        columns={columns}
        initialState={{ sorting: [{ id: 'onset-date', desc: true }] }}
        enableSortingRemoval={false}
        data={data.conditions}
      />
    </Card>
  );
}

const dateColumn: IdentifiedColumnDef<ConditionDto, string | undefined> = {
  cell: ({ getValue }) => <TabularNums>{formatDate(getValue())}</TabularNums>,
  meta: {
    width: 120,
  },
};
