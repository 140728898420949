import { Stack } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { PatientHeader } from '../../../../data-validation/PatientHeader.tsx';
import { PatientResources } from '../../../../data-validation/PatientResources.tsx';

export const Route = createFileRoute('/_main/data-validation/patient/_search/$pid')({
  component: SearchResult,
});

function SearchResult() {
  const { pid } = Route.useParams();

  return (
    <Stack gap={5}>
      <PatientHeader pid={pid} />
      <PatientResources pid={pid} />
    </Stack>
  );
}
