import { useTranslation } from 'react-i18next';
import { DosageDto } from '../../api/generated.ts';
import { TabularDosage } from './TabularDosage.tsx';
import { isTabularDosage, tabularDosageTimes, transformDosage } from './dosage.ts';

interface MedicationDosageProps {
  dosages: DosageDto[];
}

export function MedicationDosage({ dosages }: MedicationDosageProps) {
  const { t } = useTranslation();

  if (dosages.length === 0) return '-';

  const dosageMap = transformDosage(dosages);

  if (isTabularDosage(dosageMap)) {
    return <TabularDosage values={tabularDosageTimes.map((time) => dosageMap.get(time))} />;
  } else {
    return Array.from(dosageMap.entries())
      .map(([time, dose]) =>
        time
          ? t([`data-validation.medication.dosage-text.${time}`, 'data-validation.medication.dosage-text.time'], {
              dose,
              time,
            })
          : dose,
      )
      .join(', ');
  }
}
