import { Stack, Switch, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { ResourceType } from '../api/generated.ts';
import { ResourceIcon } from '../common/components/ResourceIcon.tsx';
import { SubPageHeader } from '../common/components/SubPageHeader.tsx';
import { contentWidth } from '../theme.ts';
import { PatientHeader } from './PatientHeader.tsx';

interface ValidationLayoutProps {
  resourceType: ResourceType;
  pid: string;
  children: ReactNode;
  enableFullWidth?: boolean;
}

export function ValidationLayout({ resourceType, pid, children, enableFullWidth }: ValidationLayoutProps) {
  const { t } = useTranslation();
  const [prefersFullWidth, setPrefersFullWidth] = useLocalStorage('data-validation.full-width', false);
  const isFullWidth = enableFullWidth && prefersFullWidth;

  return (
    <Stack gap={3}>
      <SubPageHeader>
        <PatientHeader pid={pid} compact />
      </SubPageHeader>
      <Stack direction="row">
        <Typography level="h4" textColor="text.secondary" startDecorator={<ResourceIcon type={resourceType} />}>
          {t(`resource-type.name.${resourceType}`, { context: 'data-validation' })}
        </Typography>
        {enableFullWidth && (
          <Switch
            size="sm"
            checked={prefersFullWidth}
            onChange={(e) => setPrefersFullWidth(e.target.checked)}
            sx={{ gap: 1, marginLeft: 'auto' }}
            endDecorator={
              <Typography level="title-sm" textColor="text.secondary">
                {t('full-width')}
              </Typography>
            }
          />
        )}
      </Stack>
      <Stack
        sx={{
          width: isFullWidth ? 'calc(100vw - 32px)' : '100%',
          marginX: isFullWidth ? `calc((${contentWidth}px - 100vw) / 2)` : 0,
          transitionProperty: 'width, margin',
          transitionDuration: '400ms',
        }}>
        {children}
      </Stack>
    </Stack>
  );
}
