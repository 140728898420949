import { createFileRoute, Outlet } from '@tanstack/react-router';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { ErrorView } from '../../errors/ErrorView.tsx';
import { notFoundErrorInterpretation } from '../../errors/interpretError.tsx';

export const Route = createFileRoute('/_main/data-validation')({
  component: DataValidationLayout,
});

function DataValidationLayout() {
  const dataValidationEnabled = useFeatureFlagEnabled('data-validation');
  if (dataValidationEnabled) {
    return <Outlet />;
  }
  return dataValidationEnabled === false ? <ErrorView {...notFoundErrorInterpretation()} /> : null;
}
