import { Box, styled } from '@healthinal/ui';
import { theme } from '../../theme.ts';

interface GridLayoutProps {
  columns: number;
  gap?: number;
}

export const GridLayout = styled(Box)(({ columns, gap }: GridLayoutProps) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  gap: theme.spacing(gap ?? 2),
  'ul&': {
    padding: 0,
    margin: 0,
  },
}));
