import { Card, IconButton, Icons, Stack, Tooltip, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'usehooks-ts';
import { formatDate } from '../i18n/date.ts';
import { theme } from '../theme.ts';
import { getPid } from './patient/utils.ts';
import { usePatientData } from './usePatientData.tsx';

interface PatientHeaderProps {
  pid: string;
  compact?: boolean;
}

export function PatientHeader({ pid, compact }: PatientHeaderProps) {
  const { t } = useTranslation();
  const { data } = usePatientData(pid);
  const [censored, toggleCensored] = useToggle(true);

  let names = [...data.patient.name[0].given, data.patient.name[0].family].filter((name) => name !== undefined);

  if (censored) {
    names = names.map((name) => name[0] + '⚹'.repeat(name.length - 1));
  }

  return (
    <Card
      variant="soft"
      sx={{
        background: theme.vars.palette.background.level1,
        gap: 2,
        ...(compact && {
          flexDirection: 'row',
          paddingY: 1,
          alignItems: 'center',
        }),
      }}>
      <Typography
        level={compact ? 'title-sm' : 'h4'}
        endDecorator={
          <Tooltip title={t('data-validation.censored-name-info')} sx={{ maxWidth: 400 }}>
            <IconButton size="sm" onClick={toggleCensored}>
              {censored ? <Icons.VisibilityOutlined fontSize="md" /> : <Icons.VisibilityOffOutlined fontSize="md" />}
            </IconButton>
          </Tooltip>
        }>
        {names.join(' ')}
      </Typography>

      <Stack direction="row" gap={3}>
        <Stack direction="row" gap={1}>
          <Typography level="body-sm">{t('data-validation.patient.birthdate')}</Typography>
          <Typography level="body-sm" textColor="text.primary">
            {formatDate(data.patient.birthDate)}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <Typography level="body-sm">{t('data-validation.patient.pid')}</Typography>
          <Typography level="body-sm" textColor="text.primary">
            {getPid(data.patient)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
