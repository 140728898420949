import { Stack, Typography } from '@healthinal/ui';
import { Fragment } from 'react';

interface TabularDosageProps {
  values: (number | string | undefined)[];
}

export function TabularDosage({ values }: TabularDosageProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      marginLeft={-1} // horizontally align single digit values with column header
    >
      {values.map((value, index) => (
        <Fragment key={index}>
          {index > 0 && <Typography textColor="divider">-</Typography>}
          <Typography width={28} textAlign="center">
            {value ?? 0}
          </Typography>
        </Fragment>
      ))}
    </Stack>
  );
}
