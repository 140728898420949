import { Icons, styled } from '@healthinal/ui';
import { Column, flexRender, getCoreRowModel, getSortedRowModel, RowData, useReactTable } from '@tanstack/react-table';
import { TableOptions } from '@tanstack/table-core';
import { ReactNode } from 'react';
import { theme } from '../../theme.ts';
import { StyledTable } from './StyledTable.tsx';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- type params need to be the same as in @tanstack/react-table
  interface ColumnMeta<TData extends RowData, TValue> {
    width?: number | string;
  }
}

export function DataTable<TData extends RowData>(
  props: Omit<TableOptions<TData>, 'getCoreRowModel'> & Partial<TableOptions<TData>>,
) {
  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...props,
  });
  return (
    <StyledTable>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const column = header.column;
              const meta = column.columnDef.meta;
              const text = header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext());
              return (
                <th key={header.id} style={{ width: meta?.width }}>
                  {column.getCanSort() ? <SortableHeader column={column}>{text}</SortableHeader> : text}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

interface SortableHeaderProps<TData extends RowData> {
  column: Column<TData>;
  children: ReactNode;
}

function SortableHeader<TData extends RowData>({ column, children }: SortableHeaderProps<TData>) {
  const order = column.getIsSorted() || column.getNextSortingOrder();
  const Icon = order && (order === 'desc' ? Icons.ArrowDownward : Icons.ArrowUpward);
  return (
    <SortableHeaderContainer onClick={column.getToggleSortingHandler()}>
      {children}
      {Icon && (
        <Icon
          fontSize="lg"
          sx={{
            color: column.getIsSorted() ? theme.vars.palette.text.icon : theme.vars.palette.neutral.plainDisabledColor,
            opacity: column.getIsSorted() ? 1 : 0, // so it can be shown when hovering SortableHeaderContainer
            transition: 'opacity 200ms',
          }}
        />
      )}
    </SortableHeaderContainer>
  );
}

const SortableHeaderContainer = styled('div')({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
  padding: '4px 8px',
  margin: '-4px -8px',
  borderRadius: theme.vars.radius.sm,
  cursor: 'pointer',
  userSelect: 'none',
  transition: 'background 200ms',
  '&:hover': {
    background: theme.vars.palette.neutral.plainHoverBg,
    svg: {
      opacity: 1,
    },
  },
});
