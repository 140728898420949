import { Checkbox, Stack, styled } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { DataProjectGrantRevocationReason } from '../api/generated.ts';
import { theme } from '../theme.ts';

interface RevokeReasonsProps {
  reasons: Set<DataProjectGrantRevocationReason>;
  onReasonsChange: (reasons: Set<DataProjectGrantRevocationReason>) => void;
}

export function RevokeReasons({ reasons, onReasonsChange }: RevokeReasonsProps) {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      {Object.values(DataProjectGrantRevocationReason).map((reason) => (
        <ReasonCheckbox
          size="sm"
          key={reason}
          label={t(`authorization.revoke.reason.${reason}`)}
          checked={reasons.has(reason)}
          onChange={(event) => {
            const newReasons = new Set(reasons);
            if (event.target.checked) {
              newReasons.add(reason);
            } else {
              newReasons.delete(reason);
            }
            onReasonsChange(newReasons);
          }}
        />
      ))}
    </Stack>
  );
}

const ReasonCheckbox = styled(Checkbox)({
  border: `solid 1px ${theme.vars.palette.neutral.outlinedBorder}`,
  padding: 12,
  borderRadius: theme.vars.radius.sm,
  '&:hover, &.Mui-checked': {
    background: theme.vars.palette.neutral.outlinedHoverBg,
  },
});
