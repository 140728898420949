import { DosageDto, EventTimingDto } from '../../api/generated.ts';

export function transformDosage(dosage: DosageDto[]) {
  return new Map(
    dosage.flatMap((dosage) => {
      if (!dosage.text) return [];

      const times: (string | undefined)[] = ((dosage.timing?.repeat?.when as string[]) ?? []).concat(
        dosage.timing?.repeat?.timeOfDay ?? [],
      );
      if (times.length === 0) {
        times.push(undefined); // use undefined as time so we retain entries without any timing information
      }
      return times.map((time) => [time, dosage.text]);
    }),
  );
}

export const tabularDosageTimes = [EventTimingDto.MORN, EventTimingDto.NOON, EventTimingDto.EVE, EventTimingDto.NIGHT];

export function isTabularDosage(dosageMap: Map<string | undefined, string | undefined>) {
  return Array.from(dosageMap.keys()).every((timing) =>
    (tabularDosageTimes as (string | undefined)[]).includes(timing),
  );
}
