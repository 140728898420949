import { PortalDataProject } from '../api/generated.ts';
import { GridLayout } from '../common/components/GridLayout.tsx';
import { DataProjectCard, DataProjectCardProps } from './DataProjectCard.tsx';

interface DataProjectsGridProps extends Pick<DataProjectCardProps, 'showDescription' | 'showGrantState'> {
  dataProjects: PortalDataProject[];
}

export function DataProjectsGrid({ dataProjects, ...cardProps }: DataProjectsGridProps) {
  return (
    <GridLayout columns={2} gap={3}>
      {dataProjects.map((dataProject) => (
        <DataProjectCard component="li" key={dataProject.id} dataProject={dataProject} {...cardProps} />
      ))}
    </GridLayout>
  );
}
