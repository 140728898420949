import { createFileRoute } from '@tanstack/react-router';
import { MedicationView } from '../../../../../data-validation/medication/MedicationView.tsx';
import { ValidationLayout } from '../../../../../data-validation/ValidationLayout.tsx';

export const Route = createFileRoute('/_main/data-validation/patient/$pid/medication')({
  component: Medication,
});

function Medication() {
  const { pid } = Route.useParams();

  return (
    <ValidationLayout resourceType="MEDICATION_STATEMENT" pid={pid} enableFullWidth>
      <MedicationView pid={pid} />
    </ValidationLayout>
  );
}
