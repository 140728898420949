import { Icons } from '@healthinal/ui';
import { useNavigate } from '@tanstack/react-router';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useReadBlockedPidsSuspense, useReadPatientDataForValidationSuspense } from '../api/generated.ts';
import { supportAction } from '../errors/interpretError.tsx';
import { useHealthcareProviderId } from '../user/useHealthcareProviderId.ts';

export function usePatientData(pid: string) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const healthcareProviderId = useHealthcareProviderId();
  const { data: pidBlocks } = useReadBlockedPidsSuspense(healthcareProviderId);

  return useReadPatientDataForValidationSuspense(
    healthcareProviderId,
    {
      pid,
    },
    {
      query: {
        staleTime: 60_000, // avoid spamming the connector with requests
        interpretError: (error) => {
          if (isAxiosError(error)) {
            if (error.response?.status === HttpStatusCode.NotFound) {
              // navigate to the main search result route (in case the error occurs on a specific resource)
              void navigate({ to: '/data-validation/patient/$pid', params: { pid }, replace: true });

              if (pidBlocks.some((pidBlock) => pidBlock.pid === pid)) {
                return {
                  title: t('data-validation.search.pid-blocked.title'),
                  description: t('data-validation.search.pid-blocked.description', { pid }),
                  icon: <Icons.PersonOffOutlined size="lg" />,
                  shouldBeReported: false,
                };
              }
              return {
                title: t('data-validation.search.not-found.title'),
                description: t('data-validation.search.not-found.description', { pid }),
                icon: <Icons.PersonSearchOutlined size="lg" />,
                shouldBeReported: false,
              };
            }
            if (error.response?.status === HttpStatusCode.BadGateway) {
              return {
                title: t('data-validation.search.unavailable.title'),
                description: t('data-validation.search.unavailable.description'),
                shouldBeReported: false,
                actions: [supportAction()],
              };
            }
          }
          return undefined;
        },
      },
    },
  );
}
