import { createFileRoute } from '@tanstack/react-router';
import { PatientView } from '../../../../../data-validation/patient/PatientView.tsx';
import { ValidationLayout } from '../../../../../data-validation/ValidationLayout.tsx';

export const Route = createFileRoute('/_main/data-validation/patient/$pid/patient')({
  component: Patient,
});

function Patient() {
  const { pid } = Route.useParams();

  return (
    <ValidationLayout resourceType="PATIENT" pid={pid}>
      <PatientView pid={pid} />
    </ValidationLayout>
  );
}
