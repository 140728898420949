import { Card, CardProps, Stack, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { GridLayout } from '../../common/components/GridLayout.tsx';
import { formatDate } from '../../i18n/date.ts';
import { usePatientData } from '../usePatientData.tsx';
import { PatientAddress } from './PatientAddress.tsx';
import { PatientTelecomList } from './PatientTelecomList.tsx';
import { getFormattedSocialSecurityNumber, getPid } from './utils.ts';

interface PatientViewProps {
  pid: string;
}

export function PatientView({ pid }: PatientViewProps) {
  const { t } = useTranslation();
  const { data } = usePatientData(pid);
  return (
    <GridLayout columns={2}>
      <PatientViewCard title={t('data-validation.patient.personal-data')}>
        <DataRow label={t('data-validation.patient.pid')}>{getPid(data.patient)}</DataRow>
        <DataRow label={t('data-validation.patient.social-security-number')}>
          {getFormattedSocialSecurityNumber(data.patient)}
        </DataRow>
        <DataRow label={t('data-validation.patient.family-name')}>{data.patient.name[0].family}</DataRow>
        <DataRow label={t('data-validation.patient.given-name')}>{data.patient.name[0].given.join(' ')}</DataRow>
        <DataRow label={t('data-validation.patient.birthdate')}>{formatDate(data.patient.birthDate)}</DataRow>
        <DataRow label={t('data-validation.patient.gender')}>
          {t(`data-validation.patient.gender-value.${data.patient.gender}`)}
        </DataRow>
      </PatientViewCard>

      <Stack gap={2}>
        <PatientViewCard title={t('data-validation.patient.contact')}>
          <DataRow label={t('data-validation.patient.phone')}>
            <PatientTelecomList contactPoints={data.patient.telecom} system="phone" />
          </DataRow>
          <DataRow label={t('data-validation.patient.email')}>
            <PatientTelecomList contactPoints={data.patient.telecom} system="email" />
          </DataRow>
        </PatientViewCard>

        <PatientViewCard
          title={t('data-validation.patient.address', { count: data.patient.address.length })}
          sx={{ gap: 2 }}>
          <Stack gap={5} direction="row" flexWrap="wrap">
            {data.patient.address.map((address, index) => (
              <PatientAddress key={index} address={address} />
            ))}
          </Stack>
        </PatientViewCard>
      </Stack>
    </GridLayout>
  );
}

function PatientViewCard({ title, children, sx, ...cardProps }: CardProps) {
  return (
    <Card {...cardProps} sx={{ gap: 2.5, ...sx }}>
      <Typography level="title-lg" textColor="text.secondary">
        {title}
      </Typography>
      {children}
    </Card>
  );
}

interface DataRowProps {
  label: string;
  children: ReactNode;
}

function DataRow({ children, label }: DataRowProps) {
  return (
    <Stack direction="row">
      <Typography width={144} flexShrink={0} textColor="text.tertiary">
        {label}
      </Typography>
      {children ?? '-'}
    </Stack>
  );
}
