import { Chip, List, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { ContactPointDto, ContactPointSystemDto } from '../../api/generated.ts';

interface PatientTelecomListProps {
  contactPoints: ContactPointDto[];
  system: ContactPointSystemDto;
}

export function PatientTelecomList({ contactPoints, system }: PatientTelecomListProps) {
  const { t } = useTranslation();
  const filteredContactPoints = contactPoints.filter((t) => t.system === system);

  if (filteredContactPoints.length === 0) {
    return '-';
  }

  return (
    <List sx={{ padding: 0, gap: 2 }}>
      {filteredContactPoints.map((contactPoint) => (
        <Typography
          key={contactPoint.value}
          component="li"
          endDecorator={
            contactPoint.use && (
              <Chip size="sm" sx={{ marginLeft: 1 }}>
                {t(`data-validation.patient.telecom-use.${contactPoint.use}`)}
              </Chip>
            )
          }>
          {contactPoint.value}
        </Typography>
      ))}
    </List>
  );
}
