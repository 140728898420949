import { Stack, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressDto } from '../../api/generated.ts';

interface PatientAddressProps {
  address: AddressDto;
}

export function PatientAddress({ address }: PatientAddressProps) {
  const { t } = useTranslation();
  return (
    <Stack gap={1}>
      <AddressPart label={t('data-validation.patient.address-line')}>{address.line.join(', ')}</AddressPart>
      <Stack direction="row" gap={2}>
        <AddressPart label={t('data-validation.patient.address-postal-code')}>{address.postalCode}</AddressPart>
        <AddressPart label={t('data-validation.patient.address-city')}>{address.city}</AddressPart>
      </Stack>
    </Stack>
  );
}

interface AddressPartProps {
  label: string;
  children: ReactNode;
}

function AddressPart({ label, children }: AddressPartProps) {
  return (
    <Stack>
      <Typography
        fontSize="xs"
        textColor="text.tertiary"
        sx={{
          userSelect: 'none', // allow easy copying of the address without labels
        }}>
        {label}
      </Typography>
      {children ?? '-'}
    </Stack>
  );
}
