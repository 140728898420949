import { Card, Chip, Icons, Link, Stack } from '@healthinal/ui';
import { createLink } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { ResourceType } from '../api/generated.ts';
import { GridLayout } from '../common/components/GridLayout.tsx';
import { ResourceIcon } from '../common/components/ResourceIcon.tsx';
import { hoverShadow } from '../common/styles/hoverShadow.ts';
import { usePatientData } from './usePatientData.tsx';

interface PatientResourcesProps {
  pid: string;
}

export function PatientResources({ pid }: PatientResourcesProps) {
  const { data } = usePatientData(pid);

  return (
    <GridLayout columns={3} as="ul">
      <PatientResourceCard resourceType="PATIENT" to="/data-validation/patient/$pid/patient" />
      <PatientResourceCard
        resourceType="MEDICATION_STATEMENT"
        to="/data-validation/patient/$pid/medication"
        count={data.medicationStatements.length}
      />
      <PatientResourceCard
        resourceType="CONDITION"
        to="/data-validation/patient/$pid/conditions"
        count={data.conditions.length}
      />
    </GridLayout>
  );
}

interface PatientResourceCardProps {
  resourceType: ResourceType;
  to: string;
  count?: number;
}

function PatientResourceCard({ resourceType, to, count }: PatientResourceCardProps) {
  const { t } = useTranslation();
  return (
    <Card component="li" sx={{ flexDirection: 'row', justifyContent: 'space-between', ...hoverShadow }}>
      <Stack direction="row" gap={1}>
        <ResourceIcon type={resourceType} />
        <RouterLink to={to} overlay underline="none" textColor="inherit">
          {t(`resource-type.name.${resourceType}`, { context: 'data-validation' })}
        </RouterLink>
        {count !== undefined && <Chip sx={{ pointerEvents: 'none' }}>{count}</Chip>}
      </Stack>
      <Icons.ChevronRight color="primary" />
    </Card>
  );
}

const RouterLink = createLink(Link);
